html,
body {
    margin: 0;
    height: 100%;
    background: #f8f8f8;
}

section {
    padding: 16px 0;
}

.all-center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.main-content {
    min-height: 100vh;
}

@media only screen and (max-width: 600px) {
    .main-area {
        margin: 8px;
    }
}

@media only screen and (min-width: 601px) {
    .main-area {
        margin: 0 13%;
    }
}

.main-area {
    padding-top: 100px;
    position: inherit;
}

/* This is a hack to make the snackbar work */
.MuiSnackbarContent-root {
    background-color: transparent !important;
}

.continue-button {
    padding-top: 8px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: stretch;
    align-content: stretch;
}

.extra-padding {
    margin: 8px 0;
}

.main-center {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: stretch;
}

.grid-item {
    min-height: 288px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: stretch;
    margin: 0px;
}

.grid-item:hover {
    background-color: #f0f0f0;
    cursor: pointer;
}

.extra-margins {
    margin: 24px 0 !important;
}

.gap {
    margin: 0 8px;
}

a {
    text-decoration: none;
    color: rgba(0, 0, 0, 0.87);
}

.stronger {
    font-weight: bolder;
}

.strong {
    font-weight: bold;
}


a:hover {
    text-decoration: underline;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
body,
button {
    font-family: Roboto, sans-serif, Arial, Helvetica;
}

::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.TableBodySortable {
    position: relative;
    z-index: 0;
    background-color: #F3F3F3;
    border: 1px solid #EFEFEF;
    border-radius: 3px;
    outline: none;
    width: 400px;
    height: 600px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    border: 1px solid #E0E0E0;
    list-style: none;
    padding: 0;
}

.TrackRow {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    height: 60px;
    padding: 0 20px;
    background-color: #FFF;
    border-bottom: 1px solid #EFEFEF;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #333;
    font-weight: 500;
    font-family: "Roboto", sans-serif;
}

.SortableHelper {
    box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.2), 0 -5px 5px -5px rgba(0, 0, 0, 0.2);
    background-color: rgba(255, 255, 255, 0.8);
    cursor: row-resize;
}


.img-responsive {
    width: 100%;
    height: auto;
}

/* BEGIN OF TAKTIKAL */

@keyframes fillandsign__modalFadeIn {
    from {
        transform: translate(0, 50px);
        opacity: 0;
    }

    to {
        transform: translate(0, 0);
        opacity: 1;
    }
}

@keyframes fillandsign__modalFadeOut {
    from {
        transform: translate(0, 0);
        opacity: 1;
    }

    to {
        transform: translate(0, 50px);
        opacity: 0;
    }
}

@keyframes fillandsign__modalBackgroundFadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fillandsign__modal {
    position: fixed;
    top: 20px;
    left: 20px;
    right: 20px;
    bottom: 20px;
    z-index: 99999;
    background: white;
    box-shadow: 0 0 40px rgba(0, 0, 0, .3);
    border-radius: 8px;
    overflow: hidden;
    opacity: 0;
    animation: fillandsign__modalFadeOut .3s cubic-bezier(.54, .03, .67, .88);
}

.fillandsign__modal.fillandsign__modal--active {
    animation: fillandsign__modalFadeIn .3s cubic-bezier(.22, .62, .4, 1);
    opacity: 1;
}

.fillandsign__modalBackground {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    opacity: 0;
    transition: opacity .2s linear;
    z-index: 99997;
}

.fillandsign__modalBackground.fillandsign__modalBackground--active {
    animation: fillandsign__modalBackgroundFadeIn .3s;
    opacity: 1;
}

.fillandsign__misclickCapture {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 10px;
    height: 10px;
    z-index: 99998;
}

.fillandsign__modal iframe {
    z-index: 100;
    margin: 0 !important;
    padding: 0 !important;
    border: 0 !important;
}

.fillandsign__cssLoaded {
    display: none;
}

/* END OF TAKTIKAL */